<template>
	<div id="Page-User-Work-View" class="main-wrap">
		<WorkView ref="WorkView" :hasTitle="step==1 || step==2">
			
			<template v-slot:left-top>
				<!-- <div class="title-viewer" v-if="step==0">作品查看</div> -->
				<template v-if="step==1 || step==2">
					<div>
						<div class="title-viewer b" v-if="step==1">{{L("初评")}}</div>
						<div class="title-viewer b" v-if="step==2">{{L('终评')}}</div>
					</div>
					<div class="mt-20 w100"></div>
					<div class="mt-10 w100"></div>
				
				</template>
			</template>
			
			<!-- <template v-slot:left-bottom>
				left-bottom
			</template> -->
			
			<!-- <template v-slot:right-top>
				right-top
			</template> -->
			
			<template v-slot:right-bottom>
				
				<div class="action-box" v-if="step==1 || step==2">
					<template v-if="!isSubmit">
						<div class="mt-20"></div>
						<div class="mt-10"></div>
						<div class="score-box flex right">
							<template v-if="step==1">
								<div class="item scoreBtn score-recomment" :class="{active:isRecomment,disabled:!isRecomment&&outRecomment}" @click="doScoreOrVote($event,true)" v-if="canRecomment">{{L("★推荐")}}</div>
								<div class="item scoreBtn score-7" :class="{active:score === 7}" @click="doScoreOrVote($event,7)">{{L("7分")}}</div>
								<div class="item scoreBtn score-5" :class="{active:score === 5}" @click="doScoreOrVote($event,5)">{{L("5分")}}</div>
								<div class="item scoreBtn score-3" :class="{active:score === 3}" @click="doScoreOrVote($event,3)">{{L("3分")}}</div>
								<div class="item scoreBtn score-0" :class="{active:score === 0}" @click="doScoreOrVote($event,0)">{{L("0分")}}</div>
							</template>
							<template v-else>
								<div class="item button " :class="{active:!!voteType,loading:isScoreLoading,'score-yes':voteType==1,'score-no':voteType==-1}" @click="doScoreOrVote($event)">{{L(voteType==1&&"已投票"||voteType==-1&&"不投票"||'待投票')}}</div>
							</template>
						</div>
					</template>
					
					<div class="mt-20"></div>
					<div class="mt-10"></div>
					<div class="flex right" >
						<div class="prev button" @click="doPrev" :class="{disabled:prev==0}">{{L("上一个")}}</div>
						<div class="next button" @click="doNext" :class="{disabled:next==0}">{{L("下一个")}}</div>
					</div>
					<div class="mt-20"></div>
					<div class="mt-10"></div>
					<div class="flex right">
						<div class="button" @click="doBack">{{L("返回")}}</div>
					</div>
				</div>
			</template>
			
			
		</WorkView>
		
		
	</div>
  
</template>

<script>
var hasCheckResult = false
import WorkView from '/src/components/WorkView.vue';
export default {
  name: 'Page-User-Work-View',
  components: {
		WorkView
  },
  data(){
    return {
			step:this.$route.params.step || 0,
			id: parseInt(this.$route.params.id) || 0,
			sid:this.$route.params.sid || 0,
			isScoreLoading:false,
			userData:null,
			isRecomment:false,
			voteType:false,
			score:-1,
			prev:0,
			next:0,
			list:[],
			isSubmit:true,
			canRecomment:false,
			outRecomment:false
    };
  },
  async mounted () {
		hasCheckResult = false;
		await this.initData();
		
  },
	watch:{
		async $route(){
			this.step = this.$route.params.step || 0;
			this.id =  parseInt(this.$route.params.id) || 0;
			this.sid = this.$route.params.sid || 0;
			this.list = [];
			await this.initData();
		}
	},
  methods: {
		async initData(){
			if(this.step == 1 || this.step == 2){
				let userData = await this.$root.initUserData();
				if(!userData){
					this.isScoreLoading = false;
					this.$root.toPage('/User/Login');
					return false;
				}
				this.$set(this,'userData',userData);
				if(this.step == 1){
					this.isRecomment = false;
					this.canRecomment = false;
					this.outRecomment = false;
					this.score = -1;
					
					if(userData && userData.config){
						if(userData.config.firstScoreList){
							let target = userData.config.firstScoreList.find(item=>item.wid == this.id);
							if(target){
								this.isRecomment = !!target.isRecomment;
								this.score = target.score;
							}
						}
					}
					
				} else {
					this.voteType = 0;
					if(userData && userData.config && userData.config.secondScoreList){
						let target = userData.config.secondScoreList.find(item=>item.wid == this.id);
						if(target){
							this.voteType = target.voteType; // -1 || 1
						}
					}
				}
				
				this.$nextTick(async ()=>{
					await this.initPrevNext(userData);
				})
			}
			
		},
		async initPrevNext(userData){
			this.next = 0;
			this.prev = 0;
			let workData = await this.$refs.WorkView.getData();
			if(!workData||!workData.awardType){
				setTimeout(async ()=>{
					await this.initPrevNext(userData);
				},1000);
				return ;
			}
			let params = {
				page:1,
				pageSize:999,
			};
			if(this.step == 1){
				params.awardWorkStatus = 11;
				params.awardType = workData.awardType;
				
				if(userData.config.recommondAwardList && userData.config.recommondAwardList.includes(workData.awardType)){
					this.canRecomment = true;
				}
				
				let recommentList = userData.config.firstScoreList.filter(item=>item.isRecomment&&item.aid==workData.awardType);
				if(userData.config.maxRecommondCount>0 && recommentList.length>=userData.config.maxRecommondCount){
					this.outRecomment = true;
				}
				
			}else if(this.step == 2){
				// params.awardWorkStatus = 11;
				params.awardWorkStatus = 21;// 已入选终评
			}
			params = this.$root.object2urlparams(params);
			let res = await this.request.get(`api/v1/awardWork/paging?${params}`);
			
			let list = res&&res.rows || [];
			let index = list.findIndex(item=>item.id == this.id);
			let next = 0, prev = 0;
			
			if(index== -1 || list.length==1){
				next = 0;
				prev = 0;
			} else if(index == list.length - 1){
				prev = list[index-1].id;
				// next = list[0].id;
				next = 0;
			} else if(index == 0){
				// prev = list[list.length - 1].id;
				prev = 0;
				next = list[index+1].id;
			} else {
				prev = list[index-1].id;
				next = list[index+1].id;
			}
			this.next = next;
			this.prev = prev;
			this.list = list;
			
			if(this.step == 1){
				if(userData && userData.config){
					if(!userData.config.firstScoreResult){
						this.isSubmit = false;
					} else {
						let result = userData.config.firstScoreResult.find(item=>item.aid==workData.awardType);
						if(!result || !result.isSubmit){
							this.isSubmit = false;
						}
					}
					
				}
			} else if(this.step == 2){
				if(userData && userData.config){
					if(!userData.config.secondScoreResult){
						this.isSubmit = false;
					} else {
						let result = userData.config.secondScoreResult.find(item=>item.aid===0);
						if(!result || !result.isSubmit){
							this.isSubmit = false;
						}
					}
					
				}
			}
			
			
			
		},
		async checkLast(userDataConfig,workData){
			if(this.step == 1){
				if(userDataConfig && userDataConfig.firstScoreList){
					let scoreCount = 0;
					this.list.forEach(item=>{
						let target = userDataConfig.firstScoreList.find(item2=>item2.wid == item.id);
						if(target) scoreCount++;
					});
					if(scoreCount == this.list.length){ 
						return true;
					}
				}
			} else if(this.step ==2){
				return false;
				// if(userDataConfig && userDataConfig.secondScoreList){
				// 	let votedCount = 0;
				// 	this.list.forEach(item=>{
				// 		let target = userDataConfig.secondScoreList.find(item2=>item2.wid == item.id);
				// 		if(target && target.voteType == 1) votedCount++;
				// 	});
				// 	let needVotedCount = this.userData.config.needVotedCount || 20;
				// 	if(votedCount == needVotedCount){ 
				// 		return true;
				// 	}
				// }
			}
			return false;
		},
		doPrev(){
			if(this.prev > 0)
			this.$root.toPage(`/Work/Content/${this.$root.md5(this.prev)}/${this.prev}/${this.step}`)
		},
		doNext(){
			if(this.next > 0)
			this.$root.toPage(`/Work/Content/${this.$root.md5(this.next)}/${this.next}/${this.step}`)
		},
		doBack(){
			if(this.step == 1){
				let workData = this.$refs.WorkView.getData();
				this.$root.toPage('/Editorial/AwardList/'+this.step+'/'+workData.awardType)
			} else if(this.step == 2){
				this.$root.toPage('/Editorial/AwardList/'+this.step);
			}
			
		},
		async getCheckUser(){
			let userData = await this.$root.initUserData();
			if(!userData){
				alert("登录失效,请重新登录");
				this.$root.toPage('/User/Login');
				return false;
			}
			return userData;
		},
		async doScoreOrVote($event,score){
			
			if(this.isScoreLoading){return ;}
			this.isScoreLoading = true;
			
			if(this.step == 1){
				
				if(!this.isRecomment && this.outRecomment && score === true){
					
					alert(`${this.L('您推荐的作品数量超过上限。您最多只能推荐')}${this.userData.config.maxRecommondCount}${this.L('个作品。')}`)
					this.isScoreLoading = false;
					return false;
				}
				$event.target.className = $event.target.className + " loading";
				
			}
			
			
			let userData = await this.getCheckUser();
			if(!userData){
				this.isScoreLoading = false;
				return false;
			}
			
			let config = {};
			let workData = this.$refs.WorkView.getData();
			if(this.step == 1){
				let firstScoreList = (userData.config &&  userData.config.firstScoreList) ? userData.config.firstScoreList : [];
				let hasIndex = firstScoreList.findIndex(item=>item.wid == this.id);
				if(hasIndex>-1){
					firstScoreList.splice(hasIndex,1);
				}
				
				firstScoreList.push({
					wid:this.id,
					aid:workData.awardType,
					score: typeof score == 'boolean' ? -1:score,
					isRecomment:score === true
				});
				
				config = this.$root.copy({...userData.config,firstScoreList});
			} else if(this.step == 2){
				let voteType = 0;
				if(!this.voteType || this.voteType == -1){
					voteType = 1;
				} else {
					voteType = -1;
				}
				let secondScoreList = (userData.config &&  userData.config.secondScoreList) ? userData.config.secondScoreList : [];
				let hasIndex = secondScoreList.findIndex(item=>item.wid == this.id);
				if(hasIndex>-1){
					secondScoreList.splice(hasIndex,1);
				}
				secondScoreList.push({
					wid:this.id,
					aid:workData.awardType,
					voteType
				});
				config = this.$root.copy({...userData.config,secondScoreList});
				
			}
			await this.request.post('/api/v1/frontDeskUser/editConfig',{config:JSON.stringify(config)}).then(async res=>{
				await this.initData();
				if(this.step == 1){
					$event.target.className = $event.target.className.replace(/loading/g,"");
				}
				this.isScoreLoading = false;
				
				if(await this.checkLast(config,workData) && !hasCheckResult){
					hasCheckResult = true;
					setTimeout(()=>{
						alert(this.L("此类别的作品已评选打分完毕，请点击“返回”按钮"));
					},500);
				}
				
				
			});
			
		}
  }
}
</script>

<style scoped lang="less">
#Page-User-Work-View{
	.title-viewer{
		font-size:26px;
	}
	.action-box{
		font-size:16px;
		line-height: 2;
		
		.prev.button.disabled,.next.button.disabled{
			cursor: default;
			border:1px solid #aaa;
			color:#aaa;
			&:hover{
				background-color: transparent;
			}
		}
	}
	.score-yes{
		&.active{
			background-color: #54F263;
			position: relative;
			width:100px;
			overflow: hidden;
			&:hover{
				color:#000000;
				background-color: #57e466;
				
			}
			&.loading{
				&::before{
					position: absolute;
					left:50%;top:50%;
					transform:translate(-50%,-50%);
				}		
			}
		}
	}
	.score-no{
		&.active{
			background-color: #FF4A4A;
			position: relative;
			width:100px;
			overflow: hidden;
			&:hover{
				color:#000000;
				background-color: #EB2626;
				
			}
			&.loading{
				&::before{
					position: absolute;
					left:50%;top:50%;
					transform:translate(-50%,-50%);
				}		
			}
		}
	}
}
</style>